var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.status === 'finished' && _vm.USER_INFO.status === 'admin')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_text),expression:"search_text"}],staticClass:"form-control form-control-sm input-form shadow-none col-md-4 m-1 d-inline",attrs:{"type":"text","placeholder":"поиск по названию СЦ"},domProps:{"value":(_vm.search_text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search_text=$event.target.value},_vm.filter]}}):_vm._e(),(_vm.years.length && _vm.status === 'finished')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.set_year),expression:"set_year"}],staticClass:"form-control form-control-sm shadow-none col-md-1  d-inline m-1",domProps:{"value":_vm.set_year},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.set_year=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.change_year]}},[_c('option',{attrs:{"value":"all"}},[_vm._v("все")]),_vm._l((_vm.years),function(item,idx){return _c('option',{key:idx + 'years',domProps:{"value":item}},[_vm._v(_vm._s(item))])})],2):_vm._e(),(
      _vm.status === 'finished' &&
        _vm.orders_list.length &&
        _vm.USER_INFO.status === 'admin'
    )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.set_count),expression:"set_count"}],staticClass:"form-control form-control-sm shadow-none col-md-2  d-inline m-1",domProps:{"value":_vm.set_count},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.set_count=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.change_count]}},[_c('option',{attrs:{"value":"100"}},[_vm._v("последние 100")]),_c('option',{attrs:{"value":"all"}},[_vm._v("все")])]):_vm._e(),(_vm.orders_list.length)?_c('table',{staticClass:"table table-sm table-hover"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("№")]),(_vm.USER_INFO.status === 'admin')?_c('th',[_vm._v("СЦ")]):_vm._e(),_c('th',[_vm._v("дата создания")]),(_vm.USER_INFO.status === 'admin' && _vm.status === 'not-finished')?_c('th',[_vm._v(" подтвержден ")]):_vm._e(),(_vm.status === 'finished')?_c('th',[_vm._v("дата отгрузки")]):_c('th',[_vm._v("статус")]),(_vm.status === 'finished')?_c('th'):_vm._e()])]),_c('tbody',{},_vm._l((_vm.orders_list),function(item,idx){return _c('tr',{key:item.id,staticClass:"cursor",class:{
          'bg-danger text-white': item.double && _vm.USER_INFO.status === 'admin',
          'bg-success':
            item.order_confirm_user &&
            _vm.USER_INFO.status === 'admin' &&
            _vm.status === 'not-finished',
          'bg-warning':
            !item.order_confirm_user &&
            _vm.USER_INFO.status === 'admin' &&
            _vm.status === 'not-finished' &&
            item.status === 'требуется подтверждение предварительного заказа'
        }},[_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(_vm._s(idx + 1))]),_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(" "+_vm._s(item.id)+" "),(item.no_read_messages > 0)?_c('i',{staticClass:"bi bi-envelope-fill"}):_vm._e()]),(_vm.USER_INFO.status === 'admin')?_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(" "+_vm._s(item.user)+" ")]):_vm._e(),_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(_vm._s(item.data))]),(_vm.USER_INFO.status === 'admin' && _vm.status === 'not-finished')?_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(" "+_vm._s(item.status === "подтвержден" ? item.status_data : "")+" ")]):_vm._e(),(_vm.status === 'finished')?_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(" "+_vm._s(item.date_order_finished)+" ")]):_c('td',{on:{"click":function($event){return _vm.getOrder(item.order_id)}}},[_vm._v(" "+_vm._s(item.status)+" ")]),(_vm.status === 'finished')?_c('td',{attrs:{"title":item.cdek_id && 'номер отслеживания СДЭК'}},[_c('a',{attrs:{"href":("https://www.cdek.ru/ru/tracking?order_id=" + (item.cdek_id)),"target":"_blanc"}},[_vm._v(_vm._s(item.cdek_id))])]):_vm._e()])}),0)]):_c('div'),(_vm.loader)?_c('Loader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }