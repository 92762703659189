<template>
  <div class="wrapper">
    <input
      v-if="status === 'finished' && USER_INFO.status === 'admin'"
      type="text"
      class="form-control form-control-sm input-form shadow-none col-md-4 m-1 d-inline"
      v-model="search_text"
      @input="filter"
      placeholder="поиск по названию СЦ"
    />

    <select
      v-if="years.length && status === 'finished'"
      class="form-control form-control-sm shadow-none col-md-1  d-inline m-1"
      v-model="set_year"
      :value="set_year"
      @change="change_year"
    >
      <option value="all">все</option>
      <option v-for="(item, idx) in years" :key="idx + 'years'" :value="item">{{
        item
      }}</option>
    </select>
    <select
      v-if="
        status === 'finished' &&
          orders_list.length &&
          USER_INFO.status === 'admin'
      "
      class="form-control form-control-sm shadow-none col-md-2  d-inline m-1"
      v-model="set_count"
      :value="set_count"
      @change="change_count"
    >
      <option value="100">последние 100</option>
      <option value="all">все</option>
    </select>
    <table class="table table-sm table-hover" v-if="orders_list.length">
      <thead>
        <tr>
          <th></th>
          <th>№</th>
          <th v-if="USER_INFO.status === 'admin'">СЦ</th>
          <th>дата создания</th>
          <th v-if="USER_INFO.status === 'admin' && status === 'not-finished'">
            подтвержден
          </th>
          <th v-if="status === 'finished'">дата отгрузки</th>
          <th v-else>статус</th>
          <th v-if="status === 'finished'"></th>
        </tr>
      </thead>
      <tbody class="">
        <tr
          v-for="(item, idx) in orders_list"
          :key="item.id"
          class="cursor"
          :class="{
            'bg-danger text-white': item.double && USER_INFO.status === 'admin',
            'bg-success':
              item.order_confirm_user &&
              USER_INFO.status === 'admin' &&
              status === 'not-finished',
            'bg-warning':
              !item.order_confirm_user &&
              USER_INFO.status === 'admin' &&
              status === 'not-finished' &&
              item.status === 'требуется подтверждение предварительного заказа'
          }"
        >
          <td @click="getOrder(item.order_id)">{{ idx + 1 }}</td>
          <td @click="getOrder(item.order_id)">
            {{ item.id }}
            <i class="bi bi-envelope-fill" v-if="item.no_read_messages > 0"></i>
          </td>
          <td
            @click="getOrder(item.order_id)"
            v-if="USER_INFO.status === 'admin'"
          >
            {{ item.user }}
          </td>
          <td @click="getOrder(item.order_id)">{{ item.data }}</td>
          <td
            v-if="USER_INFO.status === 'admin' && status === 'not-finished'"
            @click="getOrder(item.order_id)"
          >
            {{ item.status === "подтвержден" ? item.status_data : "" }}
          </td>
          <td @click="getOrder(item.order_id)" v-if="status === 'finished'">
            {{ item.date_order_finished }}
          </td>
          <td @click="getOrder(item.order_id)" v-else>
            {{ item.status }}
          </td>
          <td
            v-if="status === 'finished'"
            :title="item.cdek_id && 'номер отслеживания СДЭК'"
          >
            <a
              :href="`https://www.cdek.ru/ru/tracking?order_id=${item.cdek_id}`"
              target="_blanc"
              >{{ item.cdek_id }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else></div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from "@/components/Loader";
export default {
  name: "RepairsOrdersList",
  components: { Loader },
  props: ["status"],
  watch: {
    status: function(newVal) {
      this.getOrdersList();
      this.addPath(newVal);
    }
  },
  computed: {
    ...mapGetters([
      "USER_INFO",
      "PATH",
      "ORDERSLISTSEARCHTEXT",
      "REPAIRS_LIST_SCROLL",
      "ORDERSLISTYEAR",
      "ORDERSLISTCOUNT"
    ]),
    search_text: {
      get() {
        return this.ORDERSLISTSEARCHTEXT;
      },
      set(value) {
        this.set_search_text(value);
      }
    },
    set_year: {
      get() {
        return this.ORDERSLISTYEAR;
      },
      set(value) {
        this.set_year_order_list_filter(value);
      }
    },
    set_count: {
      get() {
        return this.ORDERSLISTCOUNT;
      },
      set(value) {
        this.set_count_order_list_filter(value);
      }
    }
  },
  data: () => ({
    orders_list: [],
    orders_list_filter: [],
    path: "",
    years: [],
    loader: false
  }),
  mounted() {
    this.getOrdersList();
    this.addPath(this.status);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations([
      "set_search_text",
      "set_repairs_list_scroll",
      "set_year_order_list_filter",
      "set_count_order_list_filter"
    ]),
    addPath(path) {
      if (path === "finished") {
        this.ADDPATH("список выполненных заявок");
      }
      if (path === "not-finished") {
        this.ADDPATH("список заявок в работе");
      }
    },
    getOrdersList() {
      this.loader = true;
      if (this.status === "not-finished") {
        this.set_search_text("");
        this.set_year_order_list_filter("all");
      }
      HTTPGET({
        url: `repairs/repairs_orders/repairs_orders_list.php`,
        params: {
          status: this.status,
          year: this.ORDERSLISTYEAR,
          count: this.ORDERSLISTCOUNT
        }
      })
        .then(response => {
          this.orders_list = this.orders_list_filter = response.data.list;
          this.years = response.data.years;
        })
        .then(() => this.filter())
        .then(() => window.scrollBy(0, this.REPAIRS_LIST_SCROLL))
        .then(() => this.set_repairs_list_scroll(0))
        .then(() => (this.loader = false));
    },
    getOrder(order_id) {
      this.$router.push(`/repairs/order/${order_id}?path=${this.PATH}`);
      this.set_repairs_list_scroll(window.pageYOffset);
    },
    filter() {
      this.orders_list = this.orders_list_filter.filter(item =>
        item.user.toLowerCase().includes(this.search_text.toLowerCase())
      );
    },
    change_year() {
      this.getOrdersList();
    },
    change_count() {
      this.getOrdersList();
    }
  }
};
</script>
<style scoped>
li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bi-envelope-fill {
  color: #ff0000;
}
table {
  font-size: 0.8em;
  width: 100%;
}
.cursor {
  cursor: pointer;
}
</style>
